import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [ "fileInput", "value", "placeholder" ]
  static values = {
    truncateFileName: { type: Boolean, default: false },
    maxFileNameSize: { type: Number, default: 28 }
  }

  open(event) {
    event.preventDefault();
    this.fileInputTarget.click();
  }

  update() {
    const input = this.fileInputTarget;
    const files = this.fileInputTarget.files;
    const url = this.fileInputTarget.dataset.directUploadUrl;

    if (files.length === 0) {
      this.placeholderTarget.classList.remove('d-none');
      this.valueTarget.classList.add('d-none');
    } else {
      if (url) {
        const upload = new DirectUpload(files[0], url);

        upload.create((error, blob) => {
        if (error) {
            console.warn('Direct upload to starage failed');
            console.warn(error);
          } else {
            const hiddenField = this.createHiddenInputField(blob.signed_id);
            hiddenField.name = input.name;
            document.querySelector('form').appendChild(hiddenField);

          }
        })
      }

      this.placeholderTarget.classList.add('d-none');
      this.valueTarget.innerHTML = this.truncateFileName(files[0].name);
      this.valueTarget.classList.remove('d-none');
    }
  }

  truncateFileName(fileName) {
    if (this.truncateFileNameValue) {
      return `${fileName.slice(0, this.maxFileNameSizeValue)}...`;
    } else {
      return fileName;
    }
  }

  createHiddenInputField(signed_id) {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("value", signed_id);
    return hiddenField;
  }
}
