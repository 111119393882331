import { loadScript } from "@paypal/paypal-js"
import { Controller } from "@hotwired/stimulus"

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}

function setFlashMessage(notification, level) {
  const container = document.querySelector("#flash");
  container.innerHTML = `<div class="alert alert-${level} alert-dismissible fade show" role="alert">
    ${notification}
    <a data-bs-dismiss="alert" class="btn-close btn-close-white" aria-label="Close" data-turbo=false></a>
  </div>`
}

export default class extends Controller {
  static values = {
    clientId: String,
    planId: Number,
    createPath: String
  }

  connect() {
    loadScript({ "client-id": this.clientIdValue, "currency": "EUR" })
      .then((paypal) => {
        window.paypal.Buttons({
          async createOrder() {
            try {
              const planId = document.querySelector('#plan_id').value;
              const gamePitchId = document.querySelector('#game_pitch_id').value;
              const data = JSON.stringify({ plan_id: planId, game_pitch_id: gamePitchId });
              const response = await fetch("/developer/paypal/orders", {
                method: "POST",
                credentials: "same-origin",
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": getMetaValue("csrf-token")
                },
                body: data
              })

              const orderData = await response.json();
              if (orderData.id) {
                return orderData.id
              } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                  ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : JSON.stringify(orderData);

                throw new Error(errorMessage);
              }
            } catch (error) {
              console.error(error);
              setFlashMessage(`Could not initiate PayPal Checkout...<br><br>${error}`, 'danger');
            }
          },
          async onApprove(data, actions) {
            try {
              const response = await fetch(`/developer/paypal/orders/${data.orderID}/capture`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": getMetaValue("csrf-token")
                },
              });

              const orderData = await response.json();
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you message

              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
              } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
              } else if (!orderData.purchase_units) {
                throw new Error(JSON.stringify(orderData));
              } else {
                // (3) Successful transaction -> Show confirmation or thank you message
                const transaction =
                  orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                  orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];

                setFlashMessage(`Transaction ${transaction.status}: ${transaction.id}<br><br>See console for all available details`, 'success')

                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2),
                );
                actions.redirect(orderData.redirect_to_path);
              }
            } catch (error) {
              console.error(error);
              setFlashMessage(`Sorry, your transaction could not be processed...<br><br>${error}`, 'danger');
            }
          }
        }).render("#paypal-button-container")
      })
  }
}
